<template>
    <div class="text-left bg-white-alt">
        <b-alert :show="alert.dismissCountDown" fade id="alert"
                 class="alert-success">{{alert.notification}}
        </b-alert>
        <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                 class="alert-danger multi-line pre-formatted">
                {{ alertDanger.notification }}
            <div class="x" style="width: 25px; height: 25px; position: absolute; background-color: transparent; right: 10px; top: 11px" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
        </b-alert>
        <NavBarNew :greenLogo='true'/>
        <main>
            <section class="tr-container" style="padding-bottom: 140px">
                <form action="" @submit.prevent="onSubmit" novalidate autofocus>
                <h1 class="tr__h1">{{ $t("public.registrationPage.authorRegistration") }}</h1>
                    <div class="form-card d-flex">
                        <div class="tr-left-part tr-border-right tr-right-dashed-line w-100">
                            <h3 style="line-height: 25px; margin: 0 auto 30px">{{ $t("public.registrationPage.joinUs") }}</h3>
                            <div class="d-flex flex-column">
                                <div class="input-groups-container">

                                    <!-- Username, Email -->
                                    <div class="form-group">
                                        <TrekioInput darkBg width="260px" id="userName" autocomplete="username" trim v-model="form.userName" :label="$t('inputLabels.username')" :error="error.userName" placeholder=" " maxlength="100" required
                                            :info="$t('info.userNameAuthor')" dynamicLabel
                                        />
                                        <TrekioInput darkBg width="260px" id="userLogin" trim v-model="form.userLogin" :label="$t('inputLabels.login')" :error="error.userLogin" placeholder=" " maxlength="50" required
                                            :info="$t('info.email')" dynamicLabel
                                        />
                                    </div>

                                    <!-- Password, RepeatPassword -->
                                    <div class="form-group">
                                        <TrekioInput darkBg type="password" width="260px" id="password" autocomplete="new-password" v-model="form.password" :label="$t('inputLabels.password')" maxLength="256"
                                            dynamicLabel :error="error.password" placeholder=" " required :info="$t('info.password')"
                                        />
                                        <TrekioInput darkBg type="password" width="260px" id="repeatPassword" autocomplete="new-password" v-model="form.repeatPassword" :label="$t('inputLabels.repeatPassword')" maxLength="256"
                                            dynamicLabel :error="error.repeatPassword" placeholder=" " required
                                        />
                                    </div>

                                    <!-- FirstName, LastName -->
                                    <div class="form-group">
                                        <TrekioInput darkBg width="260px" id="firstName" trim v-model="form.firstName" :label="$t('inputLabels.firstName')" :error="error.firstName" placeholder=" " maxlength="50" required
                                            :info="$t('info.firstNameAuthor')" dynamicLabel
                                        />
                                        <TrekioInput darkBg width="260px" id="lastName" trim v-model="form.lastName" :label="$t('inputLabels.lastName')" :error="error.lastName" placeholder=" " maxlength="50" required
                                            :info="$t('info.lastNameAuthor')" dynamicLabel
                                        />
                                    </div>

                                    <!-- DateOfBirth, PhoneNumber -->
                                    <div class="form-group">
                                        <TrekioInput darkBg type="date" width="260px" id="dateOfBirth" trim v-model="form.dateOfBirth" :label="$t('inputLabels.dateOfBirth')" :error="error.dateOfBirth" placeholder=" " maxlength="50" required
                                            :info="$t('info.dateOfBirth')" dynamicLabel
                                        />
                                        <TrekioInput darkBg width="260px" id="phoneNumber" trim v-model="form.phoneNumber" :label="$t('inputLabels.phoneNumber')" placeholder=" " maxlength="50"
                                            :info="$t('info.phoneNumber')" dynamicLabel @keypress="(e) => blockInvalidChar(e, true)"
                                        />
                                    </div>

                                    <div class="d-flex align-items-baseline">
                                        <h3 class="section-title">{{ $t("general.address") }}</h3>
                                        <div class="info-icon ml-2" v-tooltip.right-start="$t('info.address')"></div>
                                    </div>

                                    <!-- Country, City -->
                                    <div class="form-group">
                                        <TrekioSelect darkBg width="260px" :label="$t('inputLabels.country')" :placeholder="$t('inputLabels.country')" v-model="chosenCountry" :text="chosenCountry ? chosenCountry[countryLocalization] : $t('admin.notSelected')" required
                                            searchInput @searchInput="value => countriesSearchString = value" :searchString="countriesSearchString" :error="error.country"
                                        >
                                            <TrekioSelectOption v-for="country in filteredCountries" :key="country.code" :value="country">{{ country[countryLocalization] }}</TrekioSelectOption>
                                        </TrekioSelect>
                                        <TrekioInput darkBg width="260px" id="city" trim v-model="form.address.city" :label="$t('inputLabels.city')" placeholder=" " maxlength="100" required
                                            dynamicLabel :error="error.city"
                                        />
                                    </div>

                                    <!-- Street, HouseNumber -->
                                    <div class="form-group">
                                        <TrekioInput darkBg width="260px" id="street" trim v-model="form.address.street" :label="$t('inputLabels.street')" placeholder=" " maxlength="100" required
                                            dynamicLabel :error="error.street"
                                        />
                                        <TrekioInput darkBg width="260px" id="houseNumber" trim v-model="form.address.houseNumber" :label="$t('inputLabels.houseNumber')" placeholder=" " maxlength="25" required
                                            dynamicLabel :error="error.houseNumber" @keypress="blockInvalidChar"
                                        />
                                    </div>

                                    <!-- ZipCode, AdditionalInfo -->
                                    <div class="form-group">
                                        <TrekioInput darkBg width="260px" id="zipCode" trim v-model="form.address.zipCode" :label="$t('inputLabels.zipCode')" placeholder=" " maxlength="15" required
                                            dynamicLabel :error="error.zipCode"
                                        />
                                        <TrekioInput darkBg width="260px" id="additionalInfo" trim v-model="form.address.additionalInfo" :label="$t('inputLabels.additionalInfo')" placeholder=" " maxlength="4096"
                                            dynamicLabel :error="error.additionalInfo"
                                        />
                                    </div>
                                    <div>
                                        <Checkbox v-model="form.termsOfUse" id="termsOfUse" :required="true" :showError="error.termsOfUse">
                                            {{ $t("public.registrationPage.agreeWith") }} <router-link to="/termsOfUse" target="_blank">{{ $t("public.registrationPage.agreeWithTermsOfUse") }}</router-link>.
                                        </Checkbox>
                                        <Checkbox class="mt-2" v-model="form.principlesDataProcessing" id="principlesDataProcessing" :required="true" :showError="error.principlesDataProcessing">
                                            {{ $t("public.registrationPage.iHaveRead") }} <router-link to="/principlesDataProcessing" target="_blank">{{ $t("public.registrationPage.iHaveReadPrinciplesDataProcessing") }}</router-link>.
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tr-right-part tr-border-left d-flex flex-column">
                            <TrekioButton type="submit" width="260px" primary maxWidth="260px" :isLoading="loading != ''" :loadingText="loading" style="margin-bottom: 50px">{{ $t("general.register") }}</TrekioButton>
                            <div class="text-center">
                                <div>{{ $t("public.registrationPage.alreadyHaveAccount") }}</div>
                                <router-link to="/login" style="color: #30E781">{{ $t("general.login") }}</router-link>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </main>
      <Footer style="margin-top: 60px"/>
    </div>
</template>
  
  <script>
    import { AUTH_REQUEST } from "@/store/actions/auth";
    import UserService from "@/services/UserService";
    import Footer from "@/components/Footer";
    import sharedUtils from "../utils/sharedUtils";
    import {COUNTRIES} from "@/api/graphql/query/CountryQuery";
    import { userInfo } from "../components/InfoIconTexts";
    import Checkbox from "../components/Checkbox.vue";
  
    export default {
        name: "RegistrationPage",

        created() {
            this.resetForm();
        },
        components: {
            Footer,
            Checkbox
        },

        props: {
            previous: {
                type: String,
                default: 'HomePage'
            },
            id: String,
        },
  
        data: function() {
            return {
                userInfo: userInfo,
                form: {
                    userName: '',
                    userLogin: '',
                    phoneNumber: null,
                    password: '',
                    repeatPassword: '',
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '2000-04-05',
                    address: {
                        countryId: null,
                        city: '',
                        street: '',
                        houseNumber: '',
                        zipCode: null,
                        additionalInfo: '',
                    },
                    termsOfUse: false,
                    principlesDataProcessing: false,
                },
                chosenCountry: null,
                countriesSearchString: '',
                loading: '',
                alert: {
                    dismissSecs: 6,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                success: false,
                error: {
                    userName: '',
                    userLogin: '',
                    password: '',
                    repeatPassword: '',
                    phoneNumber: '',
                    country: '',
                    termsOfUse: false,
                    principlesDataProcessing: false
                },
                showPassword: {
                    password: false,
                    repeatPassword: false
                },
            }
        },
        computed: {
            countryLocalization() {
                return this.preferredLanguage == 'en' ? 'nameEnglish' : 'nameCzech'
            },
            filteredCountries() {
                if (!this.countries) return []
                return this.countries.filter(country => {
                    return country[this.countryLocalization].toLowerCase().includes(this.countriesSearchString.toLowerCase())
                }).sort((a, b) => {
                    return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
                });
            },
            preferredLanguage() {
                return this.$store.getters.preferredLanguage
            }
        },

        watch: {
            chosenCountry(nV) {
                this.form.address.countryId = nV.id
                this.countriesSearchString = ''
            },
        },
        
        methods: {
            blockInvalidChar(e, isPhoneNumber = false) {
                let allowedSymbols = ['1', '2', '3', '4', '5' ,'6' ,'7' ,'8', '9' ,'0', 'Enter']
                if (isPhoneNumber && this.form.phoneNumber.length == 0) allowedSymbols.push('+')

                if (!allowedSymbols.includes(e.key)) {
                    e.preventDefault()
                }
            },
            setCountry(country) {
                this.$refs["countries-dropdown"].hide(true);
                this.countriesSearchString = ''
                this.chosenCountry = country;
                this.form.address.countryId = country.id;
            },
            async recaptcha(action) {
                const token = await this.$recaptchaToken(action || 'LOGIN')
                return token
            },
            changePasswordVisibility(inputId) {
                this.showPassword[inputId] = !this.showPassword[inputId]
            },

            isDateAtLeast16YearsOld(inputDate) {
                const currentDate = new Date();
                const parsedInputDate = new Date(inputDate);

                const timeDifference = currentDate - parsedInputDate;
                const yearsDifference = timeDifference / (1000 * 3600 * 24 * 365.25);
                return yearsDifference >= 16;
            },

            validateInputs() {
                let errorCount = 0
                const {userLogin, password, repeatPassword, firstName, lastName, userName, termsOfUse, principlesDataProcessing} = this.form
                const inputs = document.querySelectorAll("input")
                inputs.forEach(input => this.error[input.id] = '')
                this.error.termsOfUse = this.error.principlesDataProcessing = false
                
                inputs.forEach(input => {
                    if (input.type !== 'checkbox' && input.required && !input.value) {
                        this.error[input.id] = this.$t("inputErrors.required")
                        errorCount += 1
                    }
                })

                if (firstName && !sharedUtils.stringHasOnlyLetters(firstName)) {
                    errorCount += 1
                    this.error.firstName = this.$t("inputErrors.incorrectFirstNameFormat")
                }
                
                if (lastName && !sharedUtils.stringHasOnlyLetters(lastName)) {
                    errorCount += 1
                    this.error.lastName = this.$t("inputErrors.incorrectLastNameFormat")
                }
                
                if (userName && !sharedUtils.isUserNameValid(userName)) {
                    errorCount += 1
                    this.error.userName = this.$t("inputErrors.incorrectUserNameFormat")
                }

                
                
                if (this.form.dateOfBirth && !this.isDateAtLeast16YearsOld(this.form.dateOfBirth)) {
                    errorCount += 1
                    this.error.dateOfBirth = this.$t("inputErrors.mustBeAtLeast16ToRegister")
                }
                
                if (!this.form.address.countryId) {
                    this.error.country = this.$t('inputErrors.required')
                    errorCount += 1
                } else {
                    this.error.country = ''
                }

                if (userLogin) {
                    const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@+[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/
                    if (!userLogin.match(emailRegex)) {
                        errorCount += 1
                        this.error.userLogin = this.$t("inputErrors.incorrectEmailFormat")
                    }
                }
                if (password) {
                    if (!sharedUtils.passwordHasRequiredSymbols(password)) {
                        errorCount += 1
                        this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                    }
                }
                if (repeatPassword) {
                    if (password !== repeatPassword) {
                        errorCount += 1
                        this.error.repeatPassword = this.$t("inputErrors.passwordsDoNotMatch")
                    }
                }

                if (!sharedUtils.stringHasOnlyLettersOrNumbers(this.form.address.zipCode)) {
                    errorCount += 1
                    this.error.zipCode = this.$t("inputErrors.incorrectZipCodeFormat")
                }

                if (!termsOfUse) {
                    errorCount += 1
                    this.error.termsOfUse = true
                }
                
                if (!principlesDataProcessing) {
                    errorCount += 1
                    this.error.principlesDataProcessing = true

                }
            
                if (errorCount > 0) {
                    this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
                    return false
                }

                this.alertDanger.dismissCountDown = 0
                return true
            },
            handleError(error) {
                if (error.message.includes('Password should contain')) this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                else if (error.message.includes('Username already used')) this.error.userName = this.$t("inputErrors.userNameAlreadyUsed", [this.form.userName])
                else if (error.message.includes('Login already used')) this.error.userLogin = this.$t("inputErrors.loginAlreadyUsed", [this.form.userLogin])
                else if (error.message.includes('Invalid token')) this.showAlertDanger(this.$t("alerts.registrationLinkExpiredError"))
                else this.showAlertDanger(this.$t("alerts.registrationError"))
            },
            async onSubmit() {
                console.log("OnSubmit")
                if (!this.validateInputs()) return
                this.loading = this.$t("loading.register")
                
                const username = this.form.userLogin;
                const password = this.form.password;
                const recaptchaToken = await this.recaptcha('REGISTER_AUTHOR')

                if (this.$route.params.token) {
                    await UserService.registerAuthorWithToken(this.form, recaptchaToken, this.$route.params.token).then(result => {
                        this.showAlert(this.$t('alerts.registrationSuccessful'));
                        this.success = true;
                    }).catch(error => {
                        this.handleError(error)
                        this.loading = ''
                    });
                } else {
                    await UserService.registerAuthor(this.form, recaptchaToken).then(result => {
                        this.loading = ''
                        this.showAlert(this.$t('alerts.registrationSuccessful'));
                        this.success = true;
                    }).catch(error => {
                        this.handleError(error)
                        this.loading = ''
                    });
                }
                if (this.success){
                    this.loading = this.$t("loading.login")
                    console.log("Starting authentication with username " + username);
                    const recaptchaToken = await this.recaptcha()
                    await this.$store
                        .dispatch(AUTH_REQUEST, {username, password, recaptchaToken})
                        .catch(err => this.alertDanger.notification = err);
                    await this.$router.push({name: 'adminTravelTips',}).catch(err => console.log(err))
                    }
                },

            showAlert(notification) {
                this.alert.dismissCountDown = this.alert.dismissSecs;
                this.alert.notification = notification;
            },

            showAlertDanger: function(notification) {
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
                this.alertDanger.notification = notification;
            },

            resetForm: function() {
                this.form = {
                    userName: '',
                    userLogin: '',
                    phoneNumber: '',
                    password: '',
                    repeatPassword: '',
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    address: {
                        countryId: null,
                        city: '',
                        street: '',
                        houseNumber: '',
                        zipCode: null,
                        additionalInfo: '',
                    },
                    language: this.$store.state.preferredLanguage
                }
            },
        },

        apollo: {
            countries: {
                query : COUNTRIES,
            },
        }

  
    }
</script>
  
<style lang="scss" scoped>
    @import '@/scss/variables';

.section-title {
    margin-bottom: 30px;
    font-size: 25px;
    margin-top: 20px;
}

#password {
    padding-right: 80px !important;

    & ~ .show-password {
        right: 45px;
    }
}

.input-groups-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-inline: auto;
}

.form-group {
    margin-bottom: 50px;
    gap: 40px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

h1 {
    max-width: none;
}

.dropdown-input {
  height: 60px;
  width: 100%;
  padding-inline: 20px;
  border: none;
  border-bottom: solid 2px $tr-gray;
  background-color: $tr-white;
}

 #password, #repeatPassword {
    padding-right: 55px;
 }
.show-password {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 18px;
}

.x {
    &::before {width: 16px}
    &::after {height: 16px}
}
@media screen and (min-width: 1101px) {
    .form-card {
        width: 100%;
        max-width: 1160px;
        height: fit-content;
    }
    
}



</style>

  